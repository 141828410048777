.chart-wrapper,
.alert-wrapper {
    display: flex;
    align-items: stretch;

    > .card {
        width: 100%;
    }
}

$alert-padding-x: $spacer * 0.75;
$laert-padding-y: $spacer * 1.25;

.alert-wrapper {
    .card-body {
        padding: $spacer * 2;
    }

    .card-footer {
        padding: 0;

        .btn {
            display: block;
            @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
            padding: $alert-padding-y $alert-padding-x;
        }
    }
}
