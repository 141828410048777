.btn {
    &-icon-left {
        .fa {
            margin-left: 0 !important;
            margin-right: 5px;
        }
    }

    .fa {
        margin-left: 5px;
    }
}
