$navbar-height: 85px;

.navbar {
    background: $header-color;
    box-shadow: 0 1px 0 rgba(#000, 0.1);
    height: $navbar-height;

    &-brand {
        img {
            max-height: 50px;
        }
    }

    + .app {
        height: calc(100vh - #{$navbar-height});
    }
}

.nav-link {
    &.dropdown-toggle::after {
        display: none;
    }

    .avatar {
        border-radius: 50%;
        display: inline-block;
        height: 32px;
        margin-left: 10px;
        overflow: hidden;
        position: relative;
        vertical-align: middle;
        width: 32px;

        &::before {
            background: rgba(#000, 0.5);
            content: "";
            display: none;
            height: 100%;
            position: absolute;
                top: 0;
                left: 0;
            width: 100%;
            z-index: 1;
        }

        &::after {
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-left: 0.3em solid transparent;
            color: $white;
            content: "";
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }

        img {
            max-width: 100%;
        }
    }

    &:hover {
        .avatar::before,
        .avatar::after {
            display: block;
        }
    }
}
