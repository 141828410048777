.attachment-form {
    display: flex;

    > label {
        margin-bottom: 0;
    }

    .form-control,
    .btn {
        margin-bottom: 0;
    }

    .form-control {
        width: 200px;
    }

    .btn-primary {
        margin-left: $spacer;
    }
}
