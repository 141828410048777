.actionbar {
    @extend .row;
    @extend .justify-content-between;
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;

    > div {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }

    &-actions {
        text-align: right;
    }
}
