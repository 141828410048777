// Default variables
$accent-color: #cc0000;
$primary-background-color: #e6eeeb;
$primary: #cc0000;
$primary-color-light: lighten($primary, 20%);
$secondary: #47494B;
$header-color: $secondary;

// Application Colours :)
$black: #000;
$white: #fff;
$aqua-haze: #edf0f5;
$link-water: #d7e0f1;
$silver: #ccc;
$blue-stone: $primary-color-light;
$boulder: #757575;
$dusty-gray: #999;
$mystic: #d9dfe9;
$mine-shaft: #333;
$nevada: #6a6c6f;
$gorse: $accent-color;
$scarlet: #ff3300;
$your-pink: #ffcccc;
$guardsman-red: #cc0000;
$mona-lisa: #ff9999;
$bg-yellow: #fcf5de;//#fff6d6;//#f2e7cd; ???????

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700');
$font-family-sans-serif: 'Nunito Sans', sans-serif;
