// Variables
@import 'variables';

// Vendor
@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "~@fengyuanchen/datepicker/dist/datepicker.css";

// Components
@import 'components/layout';
@import "components/app";
@import "components/nav";
@import "components/sidebar";
@import "components/card";
@import "components/dashboard";
@import "components/button";
@import "components/notification";
@import "components/table";
@import "components/actionbar";
@import "components/attachment-form";
@import "components/contact-list";
@import "components/datepicker";
@import "components/marker";

// Panels
@import 'panels/light';
@import 'panels/red';

// Blocks
@import 'blocks/filters';
@import 'blocks/navigation';

// Modules
@import 'modules/auditing';

// Custom styles
@import 'custom';
