$card-header-padding-y: $spacer * 1.25;

.card {
    margin-bottom: $grid-gutter-width;

    &-header,
    &-footer {
        background: transparent;
        border-bottom: 0;
        border-top: 0;
        padding-bottom: $card-header-padding-y;
        padding-top: $card-header-padding-y;
    }

    &-header {
        align-items: center;
        display: flex;
        justify-content: space-between;

        &:not(:first-child) {
            border-top: 1px solid $card-border-color;
            padding-top: $card-header-padding-y * 2;
        }
    }

    &-title {
        color: lighten($text-muted, 5%);
        font-size: .9rem;
        font-weight: bold;
        margin-bottom: 0;
    }

    &-fill {
        height: 100%;
    }

    &-red {
        background: $danger;
    }

    > table {
        margin-bottom: 0;

        + .card-footer {
            border-top: $card-border-width solid $card-border-color;
        }
    }

    .list-group-item {
        border-radius: 0;
        border-left: 0;
        border-right: 0;

        &:last-child {
            border-bottom: 0;
        }
    }
}
