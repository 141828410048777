.marker {
    border-radius: 3px;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: middle;

    @each $color, $hex in $theme-colors {
        &-#{$color} {
            background: $hex;
        }
    }
}
