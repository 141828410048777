.panel-red {
  background-color: $your-pink;
  border: 0;
  border: 1px solid $mona-lisa;
  border-radius: 0;

  &__heading {
    background-color: transparent !important;
    border: 0;
    border-radius: 0;

    &--large {
      padding: 30px 15px;
    }
  }

  &__title {
    border-bottom: 1px solid $mona-lisa;
    color: $guardsman-red;
    font-weight: 300;
    font-size: 14px;
    padding: 10px 0 15px;
    text-transform: uppercase;
  }

  &__amount {
    color: $white;
    margin: 0 0 5px;
  }

  &__description {
    color: $white;
  }

  &__icon {
    color: $silver;
  }

  &__cta {
    color: $guardsman-red;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__list {
    color: $mine-shaft;
    line-height: 30px;
  }

  &__footer {
    background-color: $guardsman-red;
    border-radius: 0;

    a {
      color: $white;
      text-transform: uppercase;
    }
  }
}

.no-alerts {
  background: $white;
    &__amount {
      color:  #999;
      margin: 0 0 5px;
    }
}
