.table {
    font-size: $font-size-sm;

    thead th {
        color: $text-muted;
        font-size: $font-size-sm * .95;
        min-width: 5em;
    }

    &-striped {
        tbody tr:nth-of-type(odd) {
            background-color: rgba($primary-background-color, .7);
        }
    }

    tr {
        td {
            &.extra-long-content {
                display: block;
                max-height: 120px;
                max-width: 600px;
                overflow-y: auto;
            }

            &.has-checkbox {
                padding: 0;
                position: relative;

                label {
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
            }
        }
    }
}

td.hover-to-reveal {
    &::before {
        content: attr(data-short-content);
    }

    span {
        display: none;
    }

    &:hover {
        &::before {
            content: '';
        }

        span {
            display: block;
        }
    }
}
