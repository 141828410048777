.notification {
    @extend .card;
    background-color: $white;
    color: $text-muted;
    display: flex;

    &-body {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-bottom: auto;
        padding: ($spacer * 1.5) $spacer;
    }

    &-icon {
        padding: $spacer ($spacer * 2);
        padding-left: 0;
    }

    &-info p {
        font-size: $font-size-sm;
        margin: 0;
    }

    &-count {
        color: $blue-stone;
    }

    &-action {
        background-color: $blue-stone;
        @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
        color: $white;
        display: block;
        font-size: $font-size-sm;
        padding: ($spacer * 0.75) $spacer;

        &:hover {
            background-color: darken($blue-stone, 10%);
            color: $white;
            text-decoration: none;
        }

        &:active {
            background-color: darken($blue-stone, 15%);
        }

        .fa {
            margin-left: 5px;
        }
    }
}

.notification-active {
    background-color: $blue-stone;
    color: $white;

    .notification-count {
        color: $white;
    }
}

.notification-alert {
    .notification-count {
        color: $guardsman-red;
    }

    .notification-action {
        background-color: $guardsman-red;

        &:hover {
            background-color: darken($guardsman-red, 10%);
        }

        &:active {
            background-color: darken($guardsman-red, 15%);
        }
    }

    &.notification-active {
        background-color: $guardsman-red;

        .notification-count {
            color: $white;
        }
    }
}
