$app-padding-y: $spacer * 2;
$app-padding-x: $spacer;

.app {
    @extend .d-flex;
    @extend .align-items-stretch;
    background: $primary-background-color;

    main {
        overflow: scroll;
        padding: $app-padding-y $app-padding-x;
        flex-grow: 1;
    }
}


.app-copyright {
    color: $text-muted;
    font-size: $font-size-sm;
    padding-top: $spacer * 3;
    padding-bottom: $spacer;
    text-align: center;
}

.page-title {
    margin-bottom: $app-padding-y;
}
