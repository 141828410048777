.filters {
    align-items: center;
    display: flex;
    gap: 10px;

    &--col {
        flex-direction: column;
    }

    select {
        max-width: 250px;
    }
}

.filters--bottom {
    align-self: flex-start;
}

.filters-deleted {
    display: inline-flex;

    label {
        margin-left: 1em;
    }
}

.filters--top {
    display: flex;
    align-self: flex-start;
    margin-bottom: 1em;

    >.filter {
        padding-right: 10px;
    }
}
