$sidebar-min-width: 225px;
$sidebar-max-width: 300px;

.sidebar {
    background: $white;
    box-shadow:
        1px 0 0 rgba(#000, .15),
        1px 0 4px rgba(#000, .1);
    font-size: .9rem;
    height: 100%;
    padding: $spacer;
    z-index: $zindex-sticky;

    @include media-breakpoint-up(lg) {
        display: block !important;
        min-width: $sidebar-min-width;
        max-width: $sidebar-max-width;
    }

    .nav {
        margin-left: -$spacer;
        margin-right: -$spacer;
    }

    .nav-heading {
        font-size: $font-size-sm * 0.85;
        font-weight: bold;
        opacity: .5;
        padding: ($spacer * .75) $nav-link-padding-x;
        // text-transform: uppercase;
    }

    .nav-link {
        color: $primary;
        padding-top: $spacer * .75;
        padding-bottom: $spacer * .75;

        &:hover {
            background-color: darken($accent-color, 5%);
            color: $white;
        }

        &.active {
            background: $primary;
            color: #fff;
        }
    }
}

.search-modules {
    .custom-control {
        padding-bottom: 3px;
        padding-top: 3px;
    }

    .custom-control-label {
        padding-top: 2px;

        &::before {
            background-color: rgba(#fff, 0.5);
        }
    }
}