@media all {
  .audit {

    section {
      page-break-inside: avoid;
    }

    header {
      .navbar {
        color: $white;
        display: block;
        margin-bottom: 1rem;
        padding-top: 20px;

        .client-logo {
          max-width: 20%;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    .disclaimer {
      color: $gray-600;
      font-size: 0.8rem;
      font-style: italic;
      width: 100%;

      p {
        margin: 0;
      }
    }

    .h2, .h3, .h4, .h5 {
      color: $primary;
      font-size: 3rem;
      font-weight: 900;
    }

    .h2 {
      font-size: 3rem;
    }

    .h3 {
      font-size: 2rem;
    }

    .h4 {
      font-size: 1.5rem;
    }

    .h5 {
      font-size: 1.25rem;
    }

    .store-address,
    .store-telephone {
      color: $gray-600;
      text-align: right;
    }

    .personal-licence-holders {

      .licence-holder {
        padding-bottom: 10px;

        .card {
          height: 100%;

          hr {
            margin: .25rem 0;
          }

          p {
            margin: 0;
          }

          .position-held, address {
            font-size: 0.8rem;
            color: $gray-600;
          }

          .licence-status {
            background: $orange !important;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            bottom: 0;
            color: $white;
            font-size: 0.75rem;
            left: 0;
            padding: 2px 20px;
            position: absolute;
            width: 100%;

            &.licence-granted {
              background: $green !important;
            }
          }
        }
      }
    }

    .store-number {
      color: $red;
    }

    .card-header {
      background: $primary !important;
      padding: 10px 10px 0;

      .h5 {
        color: $white;
      }
    }

    table {

      th, td {
        padding: 5px 10px;
      }

      thead {
        th {
          background: $primary !important; // Doesn't work for print without !important
          border-top: none;
          color: $white;
          font-weight: 300;
        }
      }
    }

    footer {
      color: $gray-500;
    }
  }
}