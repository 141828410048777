.contact-list {
    font-size: $font-size-sm;

    dt {
        float: left;
        text-align: center;
        width: 35px;
    }

    .fa {
        margin: 0 auto;
    }

    + .btn {
        background: #fff;
        border-color: $card-border-color;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        color: $primary;
        margin: 0 -1px -1px;
        padding: $spacer * 2;
        z-index: $zindex-dropdown - 1;

        &:hover {
            background: lighten($primary-background-color, 5%);
        }
    }
}