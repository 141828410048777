.panel-light {
  background-color: $white;
  border: 0;
  border: 1px solid $link-water;
  border-radius: 0;

  &__heading {
    border-radius: 0;
    &--large {
      padding: 30px 15px;
    }
  }

  &__title {
    color: $dusty-gray;
    font-weight: 300;
    font-size: 14px;
    padding: 14px 0 15px;
    text-transform: uppercase;
  }

  &__amount {
    color: $blue-stone;
    margin: 0 0 5px;
  }

  &__description {
    color: $boulder;
  }

  &__icon {
    color: $silver;
  }

  &__cta {
    color: $blue-stone;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__list {
    color: $mine-shaft;
    line-height: 30px;
  }

  &__footer {
    background-color: $blue-stone;
    border-radius: 0;

    a {
      color: $white;
      text-transform: uppercase;
    }
  }
}

.panel-body{
  td{
    form{
      display: inline-block;
    }
  }
}
